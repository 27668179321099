import React from "react";
import { useSearchParam } from "react-use";

import { SuccessLayoutWrapper } from "../components";

const ForgotSuccess = () => {
  const email = useSearchParam("email");
  return (
    <SuccessLayoutWrapper
      title="Password reset email sent"
      subTitle={
        <span>
          An email has been sent to{" "}
          <span style={{ color: "#a5ce39" }}>{email}</span>.
        </span>
      }
    ></SuccessLayoutWrapper>
  );
};

export default ForgotSuccess;
